<template>
    <div class="add-client position-relative">
		<div class="px-20 pt-20 pb-10 mb-20 bg-white border-bottom border-brd-primary position-relative">
			<div class="page-header d-flex align-content-center align-items-center">
				<h1 v-if="this.client_id" class="page-title mb-0 me-auto" v-text="this.title['edit']" />
				<h1 v-else class="page-title mb-0 me-auto" v-text="this.title['default']" />
				<a href="javascript:{}"
				   class="d-flex flex-row align-content-center ml-auto ms-auto me-0"
				   @click="this.goBack()">
					<span aria-label="Вернуться назад"
						  class="btn btn-outline-secondary bg-light-gray me-10 border border-brd-primary rounded-3"
						  style="padding: 0.75rem 0.95rem;">
						<img :src="require('@/assets/icons/undo.svg')" alt="Назад" width="18" height="18">
					</span>
					<span class="my-auto text-font-light">Назад</span>
				</a>
			</div>
			<div v-if="this.is_loading_process" class="d-flex vh-60 my-0 align-items-center">
				<Loader />
			</div>
			<form v-else class="border border-brd-primary border-start-0 border-end-0 border-bottom-0 py-4 mt-3 needs-validation"
				  :data-client-id="this.client_id" novalidate>

				<div v-if="this.user_cant_edit" class="alert alert-danger">
					<img :src="require('@/assets/icons/warning.svg')" class="d-inline-block me-1" alt="Ошибка" width="14" height="14" style="margin-top: -3px" />
					Недостаточно прав для редактирования данного Клиента.
				</div>

				<div class="px-0">

					<FormSteps :formSteps="this.form_steps"
							   :formStep="this.form_step"
							   @setStep="(step) => this.form_step = step" />

					<!-- Общие -->
					<div v-if="this.form_step === 1" class="mb-20">
						<InputSelect inputId="funnel_id"
									 inputName="funnel_id"
									 ref="funnel_id"
									 inputLabel="Воронка:"
									 inputLabelClass="text-font-secondary mb-3"
									 inputClass="rounded-3 p-2"
									 inputWrapClass="mb-20"
									 :inputValue="this.item.funnel_id"
									 :inputOptions="this.funnels_list"
									 :inputRequired="true"
									 :inputUsePlaceholder="false"
									 :inputPlaceholder="false"
									 @setValue="(value) => this.setAndValidate('funnel_id', value)" />

<!--						<StageBar inputId="stage"
								  inputName="stage"
								  ref="stage"
								  inputLabel="Этап:"
								  inputLabelClass="text-font-secondary mb-3"
								  inputClass="rounded-3"
								  inputWrapClass="mb-20"
								  :inputValue="this.item.stage_id"
								  :inputStages="this.stages_list"
								  inputRequired="true"
								  @setValue="(value) => this.setAndValidate('stage_id', value)" />-->

						<InputText inputId="name"
								   inputName="name"
								   ref="name"
								   inputLabel="Ф.И.О.:"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   :inputValue="this.item.name"
								   inputRequired="true"
								   @setValue="(value) => this.setAndValidate('name', value)" />

						<div class="d-flex flex-column mb-10">
							<span class="text-font-secondary mb-3">Категория клиента:</span>
							<ul class="d-flex check-group flex-wrap align-items-center m-0 p-0">
								<li class="me-20 mb-10">
									<input type="radio"
										   class="btn-check"
										   name="is_hot"
										   id="is_hot"
										   autocomplete="off"
										   :checked="this.item.is_hot === 1 || Boolean(this.item.is_hot)"
										   @click="(event) => this.setAndValidate('is_hot', 1)">
									<label class="d-flex align-items-center justify-content-center btn border border-fire btn-lg rounded-circle"
										   style="width: 50px; height: 50px;"
										   for="is_hot">
										<img :src="require('@/assets/icons/fire-icon.svg')"
											 class="d-block"
											 alt="Hot"
											 width="28"
											 height="28" />
									</label>
								</li>
								<li class="me-20 mb-10">
									<input type="radio"
										   class="btn-check"
										   name="priority"
										   id="priority_1"
										   autocomplete="off"
										   :checked="this.item.priority === 1"
										   @click="(event) => this.setAndValidate('priority', 1)">
									<label class="btn btn-raspberry btn-lg rounded-circle"
										   style="width: 50px; height: 50px;"
										for="priority_1" />
								</li>
								<li class="me-20 mb-10">
									<input type="radio"
										   class="btn-check"
										   name="priority"
										   id="priority_2"
										   autocomplete="off"
										   :checked="this.item.priority === 2"
										   @click="(event) => this.setAndValidate('priority', 2)">
									<label class="btn btn-warning btn-lg rounded-circle"
										   style="width: 50px; height: 50px;"
										for="priority_2" />
								</li>
								<li class="me-20 mb-10">
									<input type="radio"
										   class="btn-check"
										   name="priority"
										   id="priority_3"
										   autocomplete="off"
										   :checked="this.item.priority === 3"
										   @click="(event) => this.setAndValidate('priority', 3)">
									<label class="btn btn-ocean btn-lg rounded-circle"
										   style="width: 50px; height: 50px;"
										for="priority_3" />
								</li>
								<li class="me-20 mb-10">
									<input type="radio"
										   class="btn-check"
										   name="priority"
										   id="priority_4"
										   autocomplete="off"
										   :checked="this.item.priority === 4"
										   @click="(event) => this.setAndValidate('priority', 4)">
									<label class="btn btn-primary btn-lg rounded-circle"
										   style="width: 50px; height: 50px;"
										for="priority_4" />
								</li>
							</ul>
						</div>

						<InputText inputId="email"
								   inputName="email"
								   ref="email"
								   inputLabel="Эл. почта:"
								   inputClass="rounded-3 p-2"
								   inputLabelClass="text-font-secondary mb-3"
								   inputWrapClass="mb-20"
								   inputType="email"
								   inputMode="email"
								   :inputValue="this.item.email"
								   @setValue="(value) => this.setAndValidate('email', value)" />

						<div class="d-flex flex-column mb-20">
              <div class="d-flex flex-row">
                <InputText v-if="!this.national_phone"
                           inputId="phone"
                           inputName="phone"
                           ref="phone"
                           inputLabel="Телефон:"
                           inputLabelClass="text-font-secondary mb-3"
                           inputClass="rounded-3 p-2"
                           :inputValue="this.item.phone"
                           inputType="phone"
                           inputMode="tel"
                           inputWrapClass="mb-20"
                           :inputMask="['+7 (###) ###-##-##', '+7 (###) ##-##-##']"
                           inputRequired="true"
                           @setValue="(value) => this.setAndValidate('phone', value)" />
                <InputText v-else
                           inputId="phone"
                           inputName="phone"
                           ref="phone"
                           inputLabel="Телефон:"
                           inputLabelClass="text-font-secondary mb-3"
                           inputClass="rounded-3 p-2"
                           :inputValue="this.item.phone"
                           inputWrapClass="mb-20"
                           inputRequired="true"
                           @setValue="(value) => this.setAndValidate('phone', value)" />
                <Switcher class="fix-width"
                          inputId="national_phone"
                          inputLabel="Зарубежный телефон"
                          inputLabelClass="text-font-secondary mb-1"
                          inputName="national_phone"
                          ref="national_phone"
                          inputClass="ps-0 mb-4 flex-row-reverse flex-wrap-reverse justify-content-start"
                          inputWrapClass="flex-column mb-2"
                          :inputValue="this.national_phone"
                          @setValue="(value) => this.national_phone = value" />

              </div>
              <div class="d-flex flex-row" v-for="(phone, index) in this.item.phones" :key="index">
                <InputText
                    v-if="!this.national_phones[index]"
                    :inputId="'phone_' + index"
                    :inputName="'phone_' + index"
                    :ref="'phone_' + index"
                    :inputLabel="`Телефон ${index+2}:`"
                    inputLabelClass="text-font-secondary mb-3"
                    inputClass="rounded-3 p-2"
                    inputWrapClass="phone-input mb-20"
                    :inputValue="this.item.phones[index]"
                    inputType="phone"
                    inputMode="tel"
                    inputRemove="true"
                    :inputMask="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
                    @removeValue="(value) => this.removePhone(index, value)"
                    @setValue="(value) => this.setAndValidate('phones.' + index.toString(), value, true)" />
                <InputText
                    v-else
                    :inputId="'phone_' + index"
                    :inputName="'phone_' + index"
                    :ref="'phone_' + index"
                    :inputLabel="`Телефон ${index+2}:`"
                    inputLabelClass="text-font-secondary mb-3"
                    inputClass="rounded-3 p-2"
                    inputWrapClass="phone-input mb-20"
                    :inputValue="this.item.phones[index]"
                    inputRemove="true"
                    @removeValue="(value) => this.removePhone(index, value)"
                    @setValue="(value) => this.setAndValidate('phones.' + index.toString(), value, true)" />

                <Switcher class="fix-width"
                          :inputId="`national_phone_${index}`"
                          inputLabel="Зарубежный телефон"
                          inputLabelClass="text-font-secondary mb-1"
                          :inputName="`national_phone_${index}`"
                          :ref="`national_phone_${index}`"
                          inputClass="ps-0 mb-4 flex-row-reverse flex-wrap-reverse justify-content-start"
                          inputWrapClass="flex-column mb-2"
                          :inputValue="this.national_phones[index]"
                          @setValue="(value) => this.national_phones[index] = value" />
              </div>

							<div v-if="(this.phones_count < (this.phones_max-1))" class="d-flex align-items-center me-10">
								<a href="javascript:{}"
								   class="btn d-flex align-items-center p-0 mb-2 me-20 rounded-pill"
								   style="min-width: 150px;"
								   @click.stop.prevent="addPhone">
									<span class=" d-flex align-items-center justify-content-center bg-primary rounded-circle me-1"
										  style="width: 40px; height: 40px;">
										<img :src="require('@/assets/icons/add-icon.svg')"
											 class="d-block"
											 alt="Добавить"
											 width="14"
											 height="14">
									</span>
									<span class="text-font-dark fw-semi font-semi fs-4 px-2">Добавить телефон</span>
								</a>
							</div>
						</div>

						<SelectBox inputId="requisitions"
								   inputName="requisitions"
								   ref="requisitions"
								   inputLabel="Заявки:"
								   :inputOptions="this.requisitions_list"
								   :inputValue="(!this.isEmpty(this.item.requisitions)) ? this.item.requisitions : null"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   inputSearch="true"
								   inputMultiple="true"
								   inputPlaceholder="Выберите заявку(ки)"
								   inputLazyLoad="true"
								   @searchInput="(term) => this.search_query = term"
								   @endOfList="(length) => this.requisitions_offset = length"
								   @setValue="(value) => this.setAndValidate('requisitions', value)" />

						<div class="d-flex align-items-center me-10 mb-3">
							<a href="javascript:{}"
							   class="btn d-flex align-items-center p-0 mb-2 me-20 opacity-50 rounded-pill"
							   style="min-width: 150px;"
							   @click.stop.prevent="this.showNote('Функционал в стадии разработки.')">
									<span class=" d-flex align-items-center justify-content-center bg-gray rounded-circle me-1"
										  style="width: 40px; height: 40px;">
										<img :src="require('@/assets/icons/add-icon.svg')"
											 class="d-block"
											 alt="Добавить"
											 width="14"
											 height="14">
									</span>
								<span class="text-font-dark fw-semi font-semi fs-4 px-2">Добавить заявку</span>
							</a>
						</div>

						<SelectBox inputId="types"
								   inputName="types"
								   ref="types"
								   inputLabel="Тип клиента:"
								   :inputOptions="this.client_types"
								   :inputValue="this.item.types"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   inputMultiple="true"
								   inputRequired="true"
								   @setValue="(value) => this.setAndValidate('types', value)" />

						<SelectBox inputId="tags"
								   inputName="tags"
								   ref="tags"
								   inputLabel="Теги:"
								   :inputOptions="this.tags_list"
								   :inputValue="this.item.tags"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   inputMultiple="true"
								   inputSearch="true"
								   @setValue="(value) => this.setAndValidate('tags', value)" />

						<div class="row align-items-center flex-row mb-2">
							<div class="row-fluid mb-2">
								<div class="col col-4">
									<label for="contracts_list" class="form-label col text-dark-gray">
										Договоры:
									</label>
								</div>
								<div id="contracts_list" class="col col-8">
									<ul v-if="!this.isEmpty(this.item.contracts)"
										class="list-unstyled d-flex flex-column">
										<li v-for="contract in this.item.contracts" :key="contract.id"
											class="d-inline-block mb-2">
											<a href="javascript:{}"
											   class="link fs-4"
											   data-bs-toggle="modal"
											   data-bs-target="#addEditContractModal"
											   :data-bs-contract-id="contract.id"
											   :data-bs-source-id="item.id"
											   :data-bs-section="'clients'">
												<img :src="require('@/assets/icons/pen-icon.svg')" alt="Edit" class="d-inline-flex ms-0 me-1" width="14" height="14" style="margin-top: 1px;">
												{{ this.getContractType(contract.type) }}
												"{{ contract.number }}"
												с {{ this.formatDate('DD.MM.YYYY', contract.date_start) }}
												по {{ this.formatDate('DD.MM.YYYY', contract.date_end) }}
											</a>
										</li>
									</ul>
								</div>

								<a href="javascript:{}"
								   class="btn d-flex align-items-center p-0 mt-1 mb-2 me-20 rounded-pill"
								   style="min-width: 150px;"
								   data-bs-toggle="modal"
								   data-bs-target="#addEditContractModal"
								   :data-bs-contract-id="null"
								   :data-bs-source-id="item.id"
								   :data-bs-section="'clients'">
										<span class=" d-flex align-items-center justify-content-center bg-primary rounded-circle me-1"
											  style="width: 40px; height: 40px;">
											<img :src="require('@/assets/icons/add-icon.svg')"
												 class="d-block"
												 alt="Добавить"
												 width="14"
												 height="14">
										</span>
									<span class="text-font-dark fw-semi font-semi fs-4 px-2">Добавить договор</span>
								</a>
							</div>
						</div>


						<SelectBox inputId="roles"
								   inputName="roles"
								   ref="roles"
								   inputLabel="Роль клиента:"
								   :inputOptions="this.client_roles"
								   :inputValue="this.item.roles"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   inputMultiple="true"
								   inputRequired="true"
								   @setValue="(value) => this.setAndValidate('roles', value)" />

						<SelectBox inputId="estates"
								   inputName="estates"
								   ref="estates"
								   inputLabel="Тип недвижимости:"
								   :inputOptions="this.estate_types"
								   :inputValue="this.item.estates"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   inputMultiple="true"
								   @setValue="(value) => this.setAndValidate('estates', value)" />


						<div class="row align-items-center flex-row mb-2">
							<div class="col">
								<SelectBox inputId="objects"
										   inputName="objects"
										   ref="objects"
										   inputLabel="Объект(ы):"
										   :inputOptions="this.objects_list"
										   :inputValue="(!this.isEmpty(this.object_id)) ? [this.object_id] : this.item.objects"
										   inputLabelClass="text-font-secondary mb-3"
										   inputClass="rounded-3 p-2"
										   inputWrapClass="mb-20"
										   :inputDisabled="!this.isEmpty(this.object_id)"
										   inputMultiple="true"
										   inputSearch="true"
										   @setValue="(value) => this.setAndValidate('objects', value)" />
								<div class="d-flex align-items-center me-10 mb-3">
									<a href="javascript:{}"
									   class="btn d-flex align-items-center p-0 mb-2 me-20 rounded-pill"
									   style="min-width: 150px;"
									   @click.stop.prevent="this.toRoute((!this.isEmpty(this.item.id)) ? '/add-object?return=/edit-client&object_id='+this.item.id : '/add-object?return=/add-client')">
										<span class=" d-flex align-items-center justify-content-center bg-primary rounded-circle me-1"
											  style="width: 40px; height: 40px;">
											<img :src="require('@/assets/icons/add-icon.svg')"
												 class="d-block"
												 alt="Добавить"
												 width="14"
												 height="14">
										</span>
										<span class="text-font-dark fw-semi font-semi fs-4 px-2">Добавить объект</span>
									</a>
								</div>
							</div>
						</div>

						<InputText inputId="budget"
								   inputName="budget"
								   ref="budget"
								   inputLabel="Бюджет:"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   :inputValue="this.item.budget ?? null"
								   :inputMask="['# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###', '# ### ### ###']"
								   inputMode="numeric"
								   inputAppend=" руб."
								   inputStep="10000"
								   inputMin="0"
								   inputMax="2147000000"
								   inputIsPrice="true"
								   @setValue="(value) => this.setAndValidate('budget', value)" />

						<SelectBox inputId="employee_id"
								   inputName="employee_id"
								   ref="employee_id"
								   inputLabel="Ответственный:"
								   :inputOptions="this.employees_list"
								   :inputValue="this.item.employee_id"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   inputSearch="true"
								   @setValue="(value) => this.setAndValidate('employee_id', value)" />

						<!-- Кастомные поля -->
						<div v-if="!this.isEmpty(this.fields_list)" class="">
							<div v-for="(field, key) in this.fields_list" :key="field.value">
								<SelectBox v-if="!this.isEmpty(field.is_sub_group)"
										   :inputId="'fields_' + key"
										   :inputName="'fields_' + key"
										   :ref="'fields.' + key"
										   :inputLabel="field.label + ':'"
										   :inputValue="(!this.isEmpty(this.getCustomFieldValue(key))) ? this.getCustomFieldValue(key) : null"
										   :inputOptions="(!this.isEmpty(field.params.items)) ? Object.values(field.params.items) : null"
										   :inputRequired="field.is_required"
										   inputMultiple="false"
										   inputLabelClass="text-font-secondary mb-3"
										   inputClass="rounded-3 p-2"
										   inputWrapClass="mb-20"
										   @setValue="(value) => this.setAndValidate('fields.' + key, value)" />

								<div v-if="!this.isEmpty(field.is_sub_group)">

									<div v-for="(group, key2) in field.params.items" :key="group.value">
										<div v-if="(this.isEmpty(field.requisition_type) || this.inArray(this.item.type_id, field.requisition_type)) && this.inArray(group.value, this.getCustomFieldValue(key))">

											<InputText v-if="group.type == 0"
													   :inputLabel="group.name + ':'"
													   :inputValue="(!this.isEmpty(this.item.fields[key2])) ? this.item.fields[key2] : null"
													   :inputRequired="group.is_required"
													   inputLabelClass="text-font-secondary mb-3"
													   inputClass="rounded-3 p-2"
													   inputWrapClass="mb-20"
													   @setValue="(value) => this.setAndValidate('fields.' + key2, value)" />

											<InputNumber v-else-if="group.type == 1"
														 :inputLabel="group.name + ':'"
														 :inputValue="(!this.isEmpty(this.item.fields[key2])) ? this.item.fields[key2] : null"
														 :inputRequired="group.is_required"
														 inputLabelClass="text-font-secondary mb-3"
														 inputClass="rounded-3 p-2"
														 inputWrapClass="mb-20"
														 @setValue="(value) => this.setAndValidate('fields.' + key2, value)" />

											<SelectBox v-else-if="group.type == 2 || group.type == 3"
													   :inputLabel="group.name + ':'"
													   :inputValue="(!this.isEmpty(this.item.fields[key2])) ? this.item.fields[key2] : null"
													   :inputRequired="group.is_required"
													   :inputOptions="group.options"
													   :inputMultiple="(group.type == 3)"
													   inputLabelClass="text-font-secondary mb-3"
													   inputClass="rounded-3 p-2"
													   inputWrapClass="mb-20"
													   @setValue="(value) => this.setAndValidate('fields.' + key2, value)" />

											<Switcher v-else-if="group.type == 4"
													  :inputLabel="group.name"
													  :inputValue="(!this.isEmpty(this.item.fields[key2])) ? this.item.fields[key2] : null"
													  :inputRequired="group.is_required"
													  inputClass="px-0"
													  inputWrapClass="flex-column mb-3"
													  @setValue="(value) => this.setAndValidate('fields.' + key2, value)" />

											<TextArea v-else-if="group.type == 5"
													  :inputLabel="group.name + ':'"
													  :inputValue="(!this.isEmpty(this.item.fields[key2])) ? this.item.fields[key2] : null"
													  :inputRequired="group.is_required"
													  inputLabelClass="text-font-secondary mb-3"
													  inputClass="rounded-3 p-2"
													  inputWrapClass="mb-20"
													  @setValue="(value) => this.setAndValidate('fields.' + key2, value)" />

										</div>
									</div>
								</div>

								<div v-else-if="(this.isEmpty(field.requisition_type) || this.inArray(this.item.type_id, field.requisition_type))">

									<InputText v-if="field.type == 0"
											   :inputId="'fields_' + key"
											   :inputName="'fields_' + key"
											   :ref="'fields.' + key"
											   :inputLabel="field.label + ':'"
											   :inputValue="(!this.isEmpty(this.getCustomFieldValue(key))) ? this.getCustomFieldValue(key) : null"
											   :inputRequired="field.is_required"
											   inputLabelClass="text-font-secondary mb-3"
											   inputClass="rounded-3 p-2"
											   inputWrapClass="mb-20"
											   @setValue="(value) => this.setAndValidate('fields.' + key, value)" />

									<InputNumber v-else-if="field.type == 1"
												 :inputId="'fields_' + key"
												 :inputName="'fields_' + key"
												 :ref="'fields.' + key"
												 :inputLabel="field.label + ':'"
												 :inputValue="(!this.isEmpty(this.getCustomFieldValue(key))) ? this.getCustomFieldValue(key) : null"
												 :inputRequired="field.is_required"
												 inputLabelClass="text-font-secondary mb-3"
												 inputClass="rounded-3 p-2"
												 inputWrapClass="mb-20"
												 @setValue="(value) => this.setAndValidate('fields.' + key, parseInt(value))" />

									<SelectBox v-else-if="field.type == 2 || field.type == 3"
											   :inputId="'fields_' + key"
											   :inputName="'fields_' + key"
											   :ref="'fields.' + key"
											   :inputLabel="field.label + ':'"
											   :inputValue="(!this.isEmpty(this.getCustomFieldValue(key))) ? this.getCustomFieldValue(key) : null"
											   :inputRequired="field.is_required"
											   :inputOptions="(!this.isEmpty(field.params.items)) ? field.params.items : null"
											   :inputMultiple="(field.type == 3)"
											   inputLabelClass="text-font-secondary mb-3"
											   inputClass="rounded-3 p-2"
											   inputWrapClass="mb-20"
											   @setValue="(value) => this.setAndValidate('fields.' + key, value)" />

									<Switcher v-else-if="field.type == 4"
											  :inputId="'fields_' + key"
											  :inputName="'fields_' + key"
											  :ref="'fields.' + key"
											  :inputLabel="field.label"
											  :inputValue="(!this.isEmpty(this.getCustomFieldValue(key))) ? this.getCustomFieldValue(key) : null"
											  :inputRequired="field.is_required"
											  inputClass="px-0"
											  inputWrapClass="flex-column mb-3"
											  @setValue="(value) => this.setAndValidate('fields.' + key, parseInt(value))" />

									<TextArea v-else-if="field.type == 5"
											  :inputId="'fields_' + key"
											  :inputName="'fields_' + key"
											  :ref="'fields.' + key"
											  :inputLabel="field.label + ':'"
											  :inputValue="(!this.isEmpty(this.getCustomFieldValue(key))) ? this.getCustomFieldValue(key) : null"
											  :inputRequired="field.is_required"
											  inputLabelClass="text-font-secondary mb-3"
											  inputClass="rounded-3 p-2"
											  inputWrapClass="mb-20"
											  @setValue="(value) => this.setAndValidate('fields.modelp_' + key, value)" />

								</div>
							</div>
						</div>

						<SelectBox inputId="source_id"
								   inputName="source_id"
								   ref="source_id"
								   inputLabel="Источник:"
								   :inputOptions="this.sources_list"
								   :inputValue="this.item.source_id"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   inputSearch="true"
								   @setValue="(value) => this.setAndValidate('source_id', value)" />

						<SelectBox inputId="partner_id"
								   inputName="partner_id"
								   ref="partner_id"
								   inputLabel="Партнер:"
								   :inputOptions="this.partners_list"
								   :inputValue="this.item.partner_id"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   inputSearch="true"
								   @setValue="(value) => this.setAndValidate('partner_id', value)" />

						<DateTimePicker inputId="birthday"
										inputName="birthday"
										ref="birthday"
										inputLabel="День рождения:"
										inputLabelClass="text-font-secondary mb-3"
										inputClass="rounded-3 p-2"
										inputWrapClass="mb-20"
										:inputValue="this.item.birthday"
										displayFormat="DD.MM.YYYY"
										@setValue="(value) => this.setAndValidate('birthday', value)" />

						<TextArea inputId="description"
								  inputName="description"
								  ref="description"
								  inputLabel="Описание:"
								  :inputValue="this.item.description"
								  inputLabelClass="text-font-secondary mb-3"
								  inputClass="rounded-3 p-2"
								  inputWrapClass="mb-20"
								  @setValue="(value) => this.setAndValidate('description', value)" />

					</div>
					<!-- /Общие -->

					<!-- Ипотека -->
					<div v-if="this.form_step === 2" class="mb-20">

						<Switcher inputId="hypothec_is_primary"
								  inputName="hypothec_is_primary"
								  ref="hypothec.is_primary"
								  inputLabel="Первичный рынок"
								  :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.is_primary : null"
								  inputClass="px-0"
								  inputWrapClass="flex-column mb-3"
								  @setValue="(value) => this.setAndValidate('hypothec.is_primary', value)" />

						<SelectBox v-show="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.is_primary : null"
								   inputId="hypothec_complexes"
								   inputName="hypothec_complexes"
								   ref="hypothec.complexes"
								   inputLabel="Жилые комплексы:"
								   :inputOptions="this.complexes_list"
								   :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.complexes : null"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   inputSearch="true"
								   inputMultiple="true"
								   @setValue="(value) => this.setAndValidate('hypothec.complexes', value)" />

						<Switcher inputId="hypothec_is_object"
								  inputName="hypothec_is_object"
								  ref="hypothec.is_object"
								  inputLabel="Подобран объект"
								  :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.is_object : null"
								  inputClass="px-0"
								  inputWrapClass="flex-column mb-3"
								  @setValue="(value) => this.setAndValidate('hypothec.is_object', value)" />

						<div v-show="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.is_object : null" class="row">

							<InputText inputId="hypothec_object_price"
									   inputName="hypothec_object_price"
									   ref="hypothec.object_price"
									   inputLabel="Стоимость объекта:"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputWrapClass="mb-20"
									   :inputMask="['# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###', '# ### ### ###']"
									   :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.object_price : null"
									   @setValue="(value) => this.setAndValidate('hypothec.object_price', value)" />

							<InputText inputId="hypothec_first_pay"
									   inputName="hypothec_first_pay"
									   ref="hypothec.first_pay"
									   inputLabel="Размер первоначального взноса:"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputWrapClass="mb-20"
									   inputAppend="%"
									   :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.first_pay : null"
									   @setValue="(value) => this.setAndValidate('hypothec.first_pay', value)" />

							<InputText inputId="hypothec_first_pay_price"
									   inputName="hypothec_first_pay_price"
									   ref="hypothec.first_pay_price"
									   inputLabel="Размер первоначального взноса:"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputWrapClass="mb-20"
									   :inputMask="['# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###', '# ### ### ###']"
									   inputAppend="руб."
									   :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.first_pay_price : null"
									   @setValue="(value) => this.setAndValidate('hypothec.first_pay_price', value)" />

							<InputText inputId="hypothec_borrower_age"
									   inputName="hypothec_borrower_age"
									   ref="hypothec.borrower_age"
									   inputLabel="Возраст заемщика:"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputWrapClass="mb-20"
									   :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.borrower_age : null"
									   @setValue="(value) => this.setAndValidate('hypothec.borrower_age', value)" />

						</div>

						<Switcher inputId="hypothec_is_married"
								  inputName="hypothec_is_married"
								  ref="hypothec.is_married"
								  inputLabel="Состоит в браке"
								  :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.is_married : null"
								  inputClass="px-0"
								  inputWrapClass="flex-column mb-3"
								  @setValue="(value) => this.setAndValidate('hypothec.is_married', value)" />

						<Switcher v-show="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.is_married : null" inputId="hypothec_is_married_contract"
								  inputName="hypothec_is_married_contract"
								  ref="hypothec.is_married_contract"
								  inputLabel="Наличие брачного договора"
								  :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.is_married_contract : null"
								  inputClass="px-0"
								  inputWrapClass="flex-column mb-3"
								  @setValue="(value) => this.setAndValidate('hypothec.is_married_contract', value)" />

						<Switcher inputId="hypothec_is_co_borrower"
								  inputName="hypothec_is_co_borrower"
								  ref="hypothec.is_co_borrower"
								  inputLabel="Наличие созаемщика"
								  :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.is_co_borrower : null"
								  inputClass="px-0"
								  inputWrapClass="flex-column mb-3"
								  @setValue="(value) => this.setAndValidate('hypothec.is_co_borrower', value)" />

						<div v-show="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.is_co_borrower : null" class="row">

							<InputText inputId="hypothec_co_borrower_relationship"
									   inputName="hypothec_co_borrower_relationship"
									   ref="hypothec.co_borrower_relationship"
									   inputLabel="Cтепень родства по отношению к заемщику:"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputWrapClass="mb-20"
									   :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.co_borrower_relationship : null"
									   @setValue="(value) => this.setAndValidate('hypothec.co_borrower_relationship', value)" />

							<InputText inputId="hypothec_co_borrower_name"
									   inputName="hypothec_co_borrower_name"
									   ref="hypothec.co_borrower_name"
									   inputLabel="Ф.И.О. созаемщика:"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputWrapClass="mb-20"
									   :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.co_borrower_name : null"
									   @setValue="(value) => this.setAndValidate('hypothec.co_borrower_name', value)" />

							<InputText inputId="hypothec_co_borrower_age"
									   inputName="hypothec_co_borrower_age"
									   ref="hypothec.co_borrower_age"
									   inputLabel="Возраст созаемщика:"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputWrapClass="mb-20"
									   :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.co_borrower_age : null"
									   @setValue="(value) => this.setAndValidate('hypothec.co_borrower_age', value)" />

							<TextArea inputId="hypothec_co_borrower_contacts"
									  inputName="hypothec_co_borrower_contacts"
									  ref="hypothec.co_borrower_contacts"
									  inputLabel="Контактные данные созаемщика:"
									  :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.co_borrower_contacts : null"
									  inputLabelClass="text-font-secondary mb-3"
									  inputClass="rounded-3 p-2"
									  inputWrapClass="mb-20"
									  @setValue="(value) => this.setAndValidate('hypothec.co_borrower_contacts', value)" />

						</div>

						<Switcher inputId="hypothec_is_income_taken"
								  inputName="hypothec_is_income_taken"
								  ref="hypothec.is_income_taken"
								  inputLabel="Учитывается доход"
								  :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.is_income_taken : null"
								  inputClass="px-0"
								  inputWrapClass="flex-column mb-3"
								  @setValue="(value) => this.setAndValidate('hypothec.is_income_taken', value)" />

						<div v-show="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.is_income_taken : null" class="row">

							<InputText inputId="hypothec_co_borrower_income"
									   inputName="hypothec_co_borrower_income"
									   ref="hypothec.co_borrower_income"
									   inputLabel="Размер дохода:"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputWrapClass="mb-20"
									   :inputMask="['# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###', '# ### ### ###']"
									   inputAppend="руб."
									   :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.co_borrower_income : null"
									   @setValue="(value) => this.setAndValidate('hypothec.co_borrower_income', value)" />

						</div>

						<InputText inputId="hypothec_borrower_income"
								   inputName="hypothec_borrower_income"
								   ref="hypothec.borrower_income"
								   inputLabel="Совокупный доход:"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   :inputMask="['# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###', '# ### ### ###']"
								   inputAppend="руб."
								   :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.borrower_income : null"
								   @setValue="(value) => this.setAndValidate('hypothec.borrower_income', value)" />

						<InputText inputId="hypothec_borrower_credit_load"
								   inputName="hypothec_borrower_credit_load"
								   ref="hypothec.borrower_credit_load"
								   inputLabel="Кредитная нагрузка:"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   :inputMask="['# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###', '# ### ### ###']"
								   inputAppend="руб."
								   :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.borrower_credit_load : null"
								   @setValue="(value) => this.setAndValidate('hypothec.borrower_credit_load', value)" />

						<!-- div class="badge px-5 bg-danger" v-html="'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'" / -->


						<div class="d-flex flex-column mb-20 ">

							<span class="text-font-secondary mb-20">Подтверждение дохода:</span>

							<Switcher inputId="hypothec_confirm_ndfl"
									  inputName="hypothec_confirm_ndfl"
									  ref="hypothec.confirm_ndfl"
									  inputLabel="2-НДФЛ"
									  :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.confirm_ndfl : null"
									  inputClass="px-0"
									  inputWrapClass="flex-column mb-3"
									  inputDisabled="true"
									  @setValue="(value) => this.setAndValidate('hypothec.confirm_ndfl', value)" />

							<Switcher inputId="hypothec_confirm_bank_form"
									  inputName="hypothec_confirm_bank_form"
									  ref="hypothec.confirm_bank_form"
									  inputLabel="Справка по форме банка"
									  :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.confirm_bank_form : null"
									  inputClass="px-0"
									  inputWrapClass="flex-column mb-3"
									  inputDisabled="true"
									  @setValue="(value) => this.setAndValidate('hypothec.confirm_bank_form', value)" />

							<Switcher inputId="hypothec_confirm_two_docs"
									  inputName="hypothec_confirm_two_docs"
									  ref="hypothec.confirm_two_docs"
									  inputLabel="По 2-м документам"
									  :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.confirm_two_docs : null"
									  inputClass="px-0"
									  inputWrapClass="flex-column mb-3"
									  inputDisabled="true"
									  @setValue="(value) => this.setAndValidate('hypothec.confirm_two_docs', value)" />

							<Switcher inputId="hypothec_confirm_ip"
									  inputName="hypothec_confirm_ip"
									  ref="hypothec.confirm_ip"
									  inputLabel="ИП"
									  :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.confirm_ip : null"
									  inputClass="px-0"
									  inputWrapClass="flex-column mb-3"
									  inputDisabled="true"
									  @setValue="(value) => this.setAndValidate('hypothec.confirm_ip', value)" />

							<Switcher inputId="hypothec_confirm_buss_owner"
									  inputName="hypothec_confirm_buss_owner"
									  ref="hypothec.confirm_buss_owner"
									  inputLabel="Собственник бизнеса"
									  :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.confirm_buss_owner : null"
									  inputClass="px-0"
									  inputWrapClass="flex-column mb-3"
									  inputDisabled="true"
									  @setValue="(value) => this.setAndValidate('hypothec.confirm_buss_owner', value)" />

						</div>

						<div class="d-flex flex-column">
							<span class="text-font-secondary mb-20">Программа банка:</span>

							<Switcher inputId="hypothec_bank_mother_capital"
									  inputName="hypothec_bank_mother_capital"
									  ref="hypothec.bank_mother_capital"
									  inputLabel="Материнский капитал на ПВ"
									  :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.bank_mother_capital : null"
									  inputClass="px-0"
									  inputWrapClass="flex-column mb-3"
									  inputDisabled="true"
									  @setValue="(value) => this.setAndValidate('hypothec.bank_mother_capital', value)" />

							<Switcher inputId="hypothec_bank_state_support"
									  inputName="hypothec_bank_state_support"
									  ref="hypothec.bank_state_support"
									  inputLabel="Господдержка"
									  :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.bank_state_support : null"
									  inputClass="px-0"
									  inputWrapClass="flex-column mb-3"
									  inputDisabled="true"
									  @setValue="(value) => this.setAndValidate('hypothec.bank_state_support', value)" />

							<Switcher inputId="hypothec_bank_state_grant"
									  inputName="hypothec_bank_state_grant"
									  ref="hypothec.bank_state_grant"
									  inputLabel="Субсидия"
									  :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.bank_state_grant : null"
									  inputClass="px-0"
									  inputWrapClass="flex-column mb-3"
									  inputDisabled="true"
									  @setValue="(value) => this.setAndValidate('hypothec.bank_state_grant', value)" />

							<Switcher inputId="hypothec_bank_standart"
									  inputName="hypothec_bank_standart"
									  ref="hypothec.bank_standart"
									  inputLabel="Стандартные условия"
									  :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.bank_standart : null"
									  inputClass="px-0"
									  inputWrapClass="flex-column mb-3"
									  inputDisabled="true"
									  @setValue="(value) => this.setAndValidate('hypothec.bank_standart', value)" />

						</div>

						<Switcher inputId="hypothec_is_contract"
								  inputName="hypothec_is_contract"
								  ref="hypothec.is_contract"
								  inputLabel="Наличие договора"
								  :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.is_contract : null"
								  inputClass="px-0"
								  inputWrapClass="flex-column mb-3"
								  @setValue="(value) => this.setAndValidate('hypothec.is_contract', value)" />

						<div v-show="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.is_contract : null" class="row">

							<InputText inputId="hypothec_contact_number"
									   inputName="hypothec_contact_number"
									   ref="hypothec.contact_number"
									   inputLabel="Номер договора:"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputWrapClass="mb-20"
									   :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.contact_number : null"
									   @setValue="(value) => this.setAndValidate('hypothec.contact_number', value)" />

							<DateTimePicker inputId="hypothec_contact_date"
											inputName="hypothec_contact_date"
											ref="hypothec.contact_date"
											inputLabel="Дата договора:"
											inputLabelClass="text-font-secondary mb-3"
											inputClass="rounded-3 p-2"
											inputWrapClass="mb-20"
											displayFormat="DD.MM.YYYY"
											:inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.contact_date : null"
											@setValue="(value) => this.setAndValidate('hypothec.contact_date', value)" />

							<InputText inputId="hypothec_contact_commission"
									   inputName="hypothec_contact_commission"
									   ref="hypothec.contact_commission"
									   inputLabel="Размер комиссии:"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputWrapClass="mb-20"
									   inputAppend="%"
									   :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.contact_commission : null"
									   @setValue="(value) => this.setAndValidate('hypothec.contact_commission', value)" />

							<InputText inputId="hypothec_contact_commission_price"
									   inputName="hypothec_contact_commission_price"
									   ref="hypothec.contact_commission_price"
									   inputLabel="Размер комиссии:"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputWrapClass="mb-20"
									   :inputMask="['# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###', '# ### ### ###']"
									   inputAppend="руб."
									   :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.contact_commission_price : null"
									   @setValue="(value) => this.setAndValidate('hypothec.contact_commission_price', value)" />

						</div>

						<Switcher inputId="hypothec_is_bank"
								  inputName="hypothec_is_bank"
								  ref="hypothec.is_bank"
								  inputLabel="Подача в банк"
								  :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.is_bank : null"
								  inputClass="px-0"
								  inputWrapClass="flex-column mb-3"
								  @setValue="(value) => this.setAndValidate('hypothec.is_bank', value)" />

						<div v-show="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.is_bank : null" class="row"
							 :class="{'mb-3': (!this.isEmpty(this.item.hypothec.banks))}">
							<div class="col-12">
								<div v-if="!this.isEmpty(this.item.hypothec.banks)">
									<ul class="list-group list-unstyled ms-2 mb-3">
										<li v-for="(item, index) of this.item.hypothec.banks" :key="index" class="list-item">
											<div class="d-flex flex-fill align-items-center ms-0 me-0 mb-2">
												<div class="d-flex border bg-light-gray rounded-circle p-2 me-10">
													<img :src="require('@/assets/icons/bank-icon.svg')" alt="Банк" width="12" height="12">
												</div>
												<div class="d-inline-flex align-items-center flex-nowrap text-nowrap-mask mb-0 px-0 pe-4">
													{{ item.name }}
												</div>
												<button type="button"
														aria-label="Редактировать"
														:data-id="item.id"
														class="d-flex align-items-center justify-content-center btn btn-outline-secondary bg-light-gray border border-brd-primary rounded-1 me-2"
														style="width: 44px; height: 44px;"
														@click="this.showBankRequestModal('request-bank', item)">
													<img :src="require('@/assets/icons/pen-icon.svg')" class="d-block" alt="Редактировать" width="14" height="14">
												</button>
												<button type="button"
														aria-label="Удалить"
														:data-id="item.id"
														class="d-flex align-items-center justify-content-center btn btn-outline-secondary bg-light-gray border border-brd-primary rounded-1"
														style="width: 44px; height: 44px;"
														@click="this.removeBankRequest('request-bank', item.id)">
													<img :src="require('@/assets/icons/delete.svg')" class="d-block" alt="Удалить" width="14" height="14">
												</button>
											</div>
										</li>
									</ul>
								</div>
								<div v-else class="alert alert-warning rounded-3">
									Список пуст
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex align-items-center me-10 mb-3">
									<a href="javascript:{}"
									   class="btn d-flex align-items-center p-0 mb-2 me-20 rounded-pill"
									   style="min-width: 150px;"
									   data-bs-toggle="modal"
									   data-bs-target="#bankRequestModal"
									   :data-bs-section="'request-bank'">
											<span class=" d-flex align-items-center justify-content-center bg-primary rounded-circle me-1"
												  style="width: 40px; height: 40px;">
												<img :src="require('@/assets/icons/add-icon.svg')"
													 class="d-block"
													 alt="Добавить"
													 width="14"
													 height="14">
											</span>
										<span class="text-font-dark fw-semi font-semi fs-4 px-2">Добавить банк</span>
									</a>
								</div>
							</div>

<!--							<InputText inputId="hypothec_bank_employee"
									   inputName="hypothec_bank_employee"
									   ref="hypothec.bank_employee"
									   inputLabel="Ответственный сотрудник в банке:"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputWrapClass="mb-20"
									   :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.bank_employee : null"
									   @setValue="(value) => this.setAndValidate('hypothec.bank_employee', value)" />-->

<!--							<DateTimePicker inputId="hypothec_bank_date"
											inputName="hypothec_bank_date"
											ref="hypothec.bank_date"
											inputLabel="Дата подачи:"
											inputLabelClass="text-font-secondary mb-3"
											inputClass="rounded-3 p-2"
											inputWrapClass="mb-20"
											inputFormat="DD.MM.YYYY"
											:inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.bank_date : null"
											@setValue="(value) => this.setAndValidate('hypothec.bank_date', value)" />-->

						</div>

						<Switcher inputId="hypothec_is_resolve"
								  inputName="hypothec_is_resolve"
								  ref="hypothec.is_resolve"
								  inputLabel="Решение по заявке"
								  :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.is_resolve : null"
								  inputClass="px-0"
								  inputWrapClass="flex-column mb-3"
								  @setValue="(value) => this.setAndValidate('hypothec.is_resolve', value)" />

						<div v-show="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.is_resolve : null" class="row"
						:class="{'mb-3': (!this.isEmpty(this.item.hypothec.resolve))}">

							<div class="col-12">
								<div v-if="!this.isEmpty(this.item.hypothec.resolve)">
									<ul class="list-group list-unstyled ms-2 mb-3">
										<li v-for="(item, index) of this.item.hypothec.resolve" :key="index" class="list-item py-2">
											<div class="d-flex flex-fill align-items-center ms-0 me-0 mb-2">
												<div class="d-flex border bg-light-gray rounded-circle p-2 me-10">
													<img :src="require('@/assets/icons/bank-icon.svg')" alt="Банк" width="12" height="12">
												</div>
												<div class="d-inline-flex align-items-center flex-nowrap text-nowrap-mask mb-0 px-0 pe-4">
													{{ item.name }}
												</div>
												<button type="button"
														aria-label="Редактировать"
														:data-id="item.id"
														class="d-flex align-items-center justify-content-center btn btn-outline-secondary bg-light-gray border border-brd-primary rounded-1 me-2"
														style="width: 44px; height: 44px;"
														@click="this.showBankRequestModal('resolve-bank', item)">
													<img :src="require('@/assets/icons/pen-icon.svg')" class="d-block" alt="Редактировать" width="14" height="14">
												</button>
												<button type="button"
														aria-label="Удалить"
														:data-id="item.id"
														class="d-flex align-items-center justify-content-center btn btn-outline-secondary bg-light-gray border border-brd-primary rounded-1"
														style="width: 44px; height: 44px;"
														@click="this.removeBankRequest('resolve-bank', item.id)">
													<img :src="require('@/assets/icons/delete.svg')" class="d-block" alt="Удалить" width="14" height="14">
												</button>
											</div>
										</li>
									</ul>
								</div>
								<div v-else class="alert alert-warning rounded-3">
									Список пуст
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex align-items-center me-10 mb-3">
									<a href="javascript:{}"
									   class="btn d-flex align-items-center p-0 mb-2 me-20 rounded-pill"
									   style="min-width: 150px;"
									   data-bs-toggle="modal"
									   data-bs-target="#bankRequestModal"
									   :data-bs-request="this.item.hypothec.resolve"
									   :data-bs-section="'resolve-bank'">
										<span class=" d-flex align-items-center justify-content-center bg-primary rounded-circle me-1"
											  style="width: 40px; height: 40px;">
											<img :src="require('@/assets/icons/add-icon.svg')"
												 class="d-block"
												 alt="Добавить"
												 width="14"
												 height="14">
										</span>
										<span class="text-font-dark fw-semi font-semi fs-4 px-2">Добавить банк</span>
									</a>
								</div>
							</div>

							<div class="col-12">
								<SelectBox inputId="hypothec_denial"
										   inputName="hypothec_denial"
										   ref="hypothec.denial"
										   inputLabel="Отказ:"
										   :inputOptions="this.banks_list"
										   :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.denial : null"
										   inputLabelClass="text-font-secondary mb-3"
										   inputClass="rounded-3 p-2"
										   inputWrapClass="mb-20"
										   inputSearch="true"
										   inputMultiple="true"
										   @setValue="(value) => this.setAndValidate('hypothec.denial', value)" />
							</div>

							<div class="col-12">
								<SelectBox inputId="hypothec_revisal"
										   inputName="hypothec_revisal"
										   ref="hypothec.revisal"
										   inputLabel="Пересмотр:"
										   :inputOptions="this.banks_list"
										   :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.revisal : null"
										   inputLabelClass="text-font-secondary mb-3"
										   inputClass="rounded-3 p-2"
										   inputWrapClass="mb-20"
										   inputSearch="true"
										   inputMultiple="true"
										   @setValue="(value) => this.setAndValidate('hypothec.revisal', value)" />
							</div>

						</div>

						<Switcher inputId="hypothec_is_issue"
								  inputName="hypothec_is_issue"
								  ref="hypothec.is_issue"
								  inputLabel="Выдача кредита"
								  :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.is_issue : null"
								  inputClass="px-0"
								  inputWrapClass="flex-column mb-3"
								  @setValue="(value) => this.setAndValidate('hypothec.is_issue', value)" />

						<div v-show="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.is_issue : null" class="row">

							<SelectBox inputId="hypothec_bank_issue"
									   inputName="hypothec_bank_issue"
									   ref="hypothec.bank_issue"
									   inputLabel="Банк выдачи:"
									   :inputOptions="this.banks_list"
									   :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.bank_issue : null"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputWrapClass="mb-20"
									   inputMultiple="true"
									   @setValue="(value) => this.setAndValidate('hypothec.bank_issue', value)" />

							<InputText inputId="hypothec_issue_rate"
									   inputName="hypothec_issue_rate"
									   ref="hypothec.issue_rate"
									   inputLabel="Ставка:"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputWrapClass="mb-20"
									   :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.issue_rate : null"
									   inputAppend="%"
									   @setValue="(value) => this.setAndValidate('hypothec.issue_rate', value)" />

							<InputText inputId="hypothec_issue_term"
									   inputName="hypothec_issue_term"
									   ref="hypothec.issue_term"
									   inputLabel="Срок кредита:"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputWrapClass="mb-20"
									   :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.issue_term : null"
									   @setValue="(value) => this.setAndValidate('hypothec.issue_term', value)" />

						</div>

						<TextArea inputId="hypothec_comment"
								  inputName="hypothec_comment"
								  ref="hypothec.comment"
								  inputLabel="Комментарий:"
								  :inputValue="(!this.isEmpty(this.item.hypothec)) ? this.item.hypothec.comment : null"
								  inputLabelClass="text-font-secondary mb-3"
								  inputClass="rounded-3 p-2"
								  inputWrapClass="mb-20"
								  @setValue="(value) => this.setAndValidate('hypothec.comment', value)" />

					</div>
					<!-- /Ипотека -->

					<!-- Кредит -->
					<div v-if="this.form_step === 3" class="mb-20">

						<SelectBox inputId="credit_product_id"
								   inputName="credit_product_id"
								   ref="credit.product_id"
								   inputLabel="Банковский продукт:"
								   :inputOptions="this.bank_products"
								   :inputValue="(!this.isEmpty(this.item.credit)) ? this.item.credit.product_id : null"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   inputMultiple="true"
								   @setValue="(value) => this.setAndValidate('credit.product_id', value)" />

						<InputText inputId="credit_sum"
								   inputName="credit_sum"
								   ref="credit.sum"
								   inputLabel="Сумма:"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   :inputValue="(!this.isEmpty(this.item.credit)) ? this.item.credit.sum : null"
								   :inputMask="['# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###', '# ### ### ###']"
								   inputAppend="руб."
								   @setValue="(value) => this.setAndValidate('credit.sum', value)" />

						<InputText inputId="credit_commission"
								   inputName="credit_commission"
								   ref="credit.commission"
								   inputLabel="Размер комиссии:"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   :inputValue="(!this.isEmpty(this.item.credit)) ? this.item.credit.commission : null"
								   inputAppend="%"
								   @setValue="(value) => this.setAndValidate('credit.commission', value)" />

						<InputText inputId="credit_target"
								   inputName="credit_target"
								   ref="credit.target"
								   inputLabel="Цель:"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   :inputValue="(!this.isEmpty(this.item.credit)) ? this.item.credit.target : null"
								   @setValue="(value) => this.setAndValidate('credit.target', value)" />

						<InputText inputId="credit_age"
								   inputName="credit_age"
								   ref="credit.age"
								   inputLabel="Возраст:"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   :inputValue="(!this.isEmpty(this.item.credit)) ? this.item.credit.age : null"
								   @setValue="(value) => this.setAndValidate('credit.age', value)" />

						<InputText inputId="credit_registration"
								   inputName="credit_registration"
								   ref="credit.registration"
								   inputLabel="Прописка:"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   :inputValue="(!this.isEmpty(this.item.credit)) ? this.item.credit.registration : null"
								   @setValue="(value) => this.setAndValidate('credit.registration', value)" />

						<div class="row align-items-end">
							<div class="col-8">
								<InputText inputId="credit_load"
										   inputName="credit_load"
										   ref="credit.load"
										   inputLabel="Нагрузка по кредитам и кредиткам:"
										   inputLabelClass="text-font-secondary mb-3"
										   inputClass="rounded-3 p-2"
										   inputWrapClass="mb-20"
										   :inputValue="(!this.isEmpty(this.item.credit)) ? this.item.credit.load : null"
										   :inputMask="['# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###', '# ### ### ###']"
										   inputAppend="руб."
										   @setValue="(value) => this.setAndValidate('credit.load', value)" />
							</div>
							<div class="col-4">
								<InputText inputId="credit_load_cards"
										   inputName="credit_load_cards"
										   ref="credit.load_cards"
										   inputLabel=""
										   inputLabelClass="text-font-secondary mb-3"
										   inputClass="rounded-3 p-2"
										   inputWrapClass="mb-20"
										   :inputValue="(!this.isEmpty(this.item.credit)) ? this.item.credit.load_cards : null"
										   @setValue="(value) => this.setAndValidate('credit.load_cards', value)" />
							</div>
						</div>

						<InputText inputId="credit_income"
								   inputName="credit_income"
								   ref="credit.income"
								   inputLabel="Подтверждаемый доход:"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   :inputValue="(!this.isEmpty(this.item.credit)) ? this.item.credit.income : null"
								   :inputMask="['# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###', '# ### ### ###']"
								   inputAppend="руб."
								   @setValue="(value) => this.setAndValidate('credit.income', value)" />


						<div class="d-flex flex-column mb-20 ">
							<span class="text-font-secondary me-auto mb-20">Подтверждение дохода:</span>
							<Switcher inputId="credit_verify_by_ndfl"
									  inputName="credit_verify_by_ndfl"
									  ref="credit.verify_by_ndfl"
									  inputLabel="2-НДФЛ"
									  :inputValue="(!this.isEmpty(this.item.credit)) ? this.item.credit.verify_by_ndfl : null"
									  inputClass="px-0"
									  inputWrapClass="flex-column mb-3"
									  @setValue="(value) => this.setAndValidate('credit.verify_by_ndfl', value)" />

							<Switcher inputId="credit_verify_by_inquiry"
									  inputName="credit_verify_by_inquiry"
									  ref="credit.verify_by_inquiry"
									  inputLabel="Форма банка"
									  :inputValue="(!this.isEmpty(this.item.credit)) ? this.item.credit.verify_by_inquiry : null"
									  inputClass="px-0"
									  inputWrapClass="flex-column mb-3"
									  @setValue="(value) => this.setAndValidate('credit.verify_by_inquiry', value)" />

							<Switcher inputId="credit_verify_by_phone"
									  inputName="credit_verify_by_phone"
									  ref="credit.verify_by_phone"
									  inputLabel="По звонку"
									  :inputValue="(!this.isEmpty(this.item.credit)) ? this.item.credit.verify_by_phone : null"
									  inputClass="px-0"
									  inputWrapClass="flex-column mb-3"
									  @setValue="(value) => this.setAndValidate('credit.verify_by_phone', value)" />

							<InputText inputId="credit_query_count"
									   inputName="credit_query_count"
									   ref="credit.query_count"
									   inputLabel="Количество подач за последние 30 дней:"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputWrapClass="mb-20"
									   :inputValue="(!this.isEmpty(this.item.credit)) ? this.item.credit.query_count : null"
									   @setValue="(value) => this.setAndValidate('credit.query_count', value)" />

						</div>

						<div class="d-flex flex-column mb-20 ">

							<span class="text-font-secondary me-auto mb-20">Собственность:</span>

							<Switcher inputId="credit_owner_by_flat"
									  inputName="credit_owner_by_flat"
									  ref="credit.owner_by_flat"
									  inputLabel="Квартира"
									  :inputValue="(!this.isEmpty(this.item.credit)) ? this.item.credit.owner_by_flat : null"
									  inputClass="px-0"
									  inputWrapClass="flex-column mb-3"
									  @setValue="(value) => this.setAndValidate('credit.owner_by_flat', value)" />

							<Switcher inputId="credit_owner_by_house"
									  inputName="credit_owner_by_house"
									  ref="credit.owner_by_house"
									  inputLabel="Дом"
									  :inputValue="(!this.isEmpty(this.item.credit)) ? this.item.credit.owner_by_house : null"
									  inputClass="px-0"
									  inputWrapClass="flex-column mb-3"
									  @setValue="(value) => this.setAndValidate('credit.owner_by_house', value)" />

							<Switcher inputId="credit_owner_by_area"
									  inputName="credit_owner_by_area"
									  ref="credit.owner_by_area"
									  inputLabel="Участок"
									  :inputValue="(!this.isEmpty(this.item.credit)) ? this.item.credit.owner_by_area : null"
									  inputClass="px-0"
									  inputWrapClass="flex-column mb-3"
									  @setValue="(value) => this.setAndValidate('credit.owner_by_area', value)" />

							<Switcher inputId="credit_owner_by_car"
									  inputName="credit_owner_by_car"
									  ref="credit.owner_by_car"
									  inputLabel="Авто"
									  :inputValue="(!this.isEmpty(this.item.credit)) ? this.item.credit.owner_by_car : null"
									  inputClass="px-0"
									  inputWrapClass="flex-column mb-3"
									  @setValue="(value) => this.setAndValidate('credit.owner_by_car', value)" />

							<Switcher inputId="credit_not_owner"
									  inputName="credit_not_owner"
									  ref="credit.not_owner"
									  inputLabel="Нет"
									  :inputValue="(!this.isEmpty(this.item.credit)) ? this.item.credit.not_owner : null"
									  inputClass="px-0"
									  inputWrapClass="flex-column mb-3"
									  @setValue="(value) => this.setAndValidate('credit.not_owner', value)" />

						</div>

						<div class="d-flex flex-column mb-20 ">

							<span class="text-font-secondary me-auto mb-20">Наличие отчислений:</span>

							<Switcher inputId="credit_deduction"
									  inputName="credit_deduction"
									  ref="credit.deduction"
									  inputLabel="Да/нет"
									  :inputValue="(!this.isEmpty(this.item.credit)) ? this.item.credit.deduction : null"
									  inputClass="px-0"
									  inputWrapClass="flex-column mb-3"
									  @setValue="(value) => this.setAndValidate('credit.deduction', value)" />

						</div>

						<div class="d-flex flex-column mb-20 ">

							<span class="text-font-secondary me-auto mb-20">Просрочки:</span>

							<Switcher inputId="credit_overdue"
									  inputName="credit_overdue"
									  ref="credit.overdue"
									  inputLabel="Действующие"
									  :inputValue="(!this.isEmpty(this.item.credit)) ? (this.item.credit.overdue == 1) : false"
									  inputClass="px-0"
									  inputWrapClass="flex-column mb-3"
									  @setValue="(value) => this.setAndValidate('credit.overdue', 1)" />

							<Switcher inputId="credit_overdue2"
									  inputName="credit_overdue2"
									  ref="credit.overdue2"
									  inputLabel="Исторические"
									  :inputValue="(!this.isEmpty(this.item.credit)) ? (this.item.credit.overdue == 2) : false"
									  inputClass="px-0"
									  inputWrapClass="flex-column mb-3"
									  @setValue="(value) => this.setAndValidate('credit.overdue', 2)" />

							<Switcher inputId="credit_overdue3"
									  inputName="credit_overdue3"
									  ref="credit.overdue3"
									  inputLabel="Нет"
									  :inputValue="(!this.isEmpty(this.item.credit)) ? (this.item.credit.overdue == 3) : false"
									  inputClass="px-0"
									  inputWrapClass="flex-column mb-3"
									  @setValue="(value) => this.setAndValidate('credit.overdue', 3)" />

						</div>

						<div class="d-flex flex-column mb-20 ">

							<span class="text-font-secondary me-auto mb-20">Наличие МФО:</span>

							<Switcher inputId="credit_mfo"
									  inputName="credit_mfo"
									  ref="credit.mfo"
									  inputLabel="Действующие"
									  :inputValue="(!this.isEmpty(this.item.credit)) ? (this.item.credit.mfo == 1) : null"
									  inputClass="px-0"
									  inputWrapClass="flex-column mb-3"
									  @setValue="(value) => this.setAndValidate('credit.mfo', 1)" />

							<Switcher inputId="credit_mfo2"
									  inputName="credit_mfo2"
									  ref="credit.mfo2"
									  inputLabel="Исторические"
									  :inputValue="(!this.isEmpty(this.item.credit)) ? (this.item.credit.mfo == 2) : null"
									  inputClass="px-0"
									  inputWrapClass="flex-column mb-3"
									  @setValue="(value) => this.setAndValidate('credit.mfo', 2)" />

							<Switcher inputId="credit_mfo3"
									  inputName="credit_mfo3"
									  ref="credit.mfo3"
									  inputLabel="Нет"
									  :inputValue="(!this.isEmpty(this.item.credit)) ? (this.item.credit.mfo == 3) : null"
									  inputClass="px-0"
									  inputWrapClass="flex-column mb-3"
									  @setValue="(value) => this.setAndValidate('credit.mfo', 3)" />

						</div>

					</div>
					<!-- /Кредит -->

					<!-- Объекты -->
					<div v-if="!this.isEmpty(this.item.objects) && this.form_step === 4" class="mb-20">
						<ObjectsList :isGlobalSearch="false"
									 currentSection="clients"
									 :inputFilters="{ objects_ids: this.item.objects }"
									 layout="short" />
					</div>
					<div v-else-if="this.form_step === 4" class="mb-20">
						<EmptyList message="Объекты не найдены" />
					</div>
					<!-- /Объекты -->

					<!-- Заявки -->
					<div v-if="this.form_step === 5" class="mb-20">
						<div v-if="this.requisitions_proccessed || !this.isEmpty(this.requisitions)">
							<div v-if="this.requisitions_proccessed">
								<Loader v-if="this.requisitions_proccessed" />
							</div>
							<div v-else-if="!this.isEmpty(this.requisitions)">
								<RequestCard v-for="requisition in this.requisitions"
											 :key="requisition.id"
											 :requisitionItem="requisition"
											 :tagsList="this.tags_list2"
											 :objectsList="this.objects_list"
											 :clientsList="this.clients_list"
											 :sourcesList="this.sources_list"
											 :partnersList="this.partners_list"
											 :contractTypes="this.contract_types"
											 :funnelsList="this.funnels_list"
											 :typesList="this.types_list"
											 layout="short" />
							</div>
						</div>
<!--						<div v-else-if="this.form_step === 5 && this.requisitions === null" class="mb-20">-->
            <div v-else-if="this.form_step === 5 && this.isEmpty(this.requisitions)" class="mb-20">
							<EmptyList message="Заявки не найдены" />
						</div>
						<div v-else>
							<Loader />
						</div>
					</div>
					<!-- /Заявки -->

					<!-- Для документов -->
					<div v-if="this.form_step === 6" class="mb-20">

						<div v-if="this.variables_list">
							<div v-for="(variable, key) in this.variables_list" :key="variable.id">
								<InputText :inputId="'variables_' + key"
										   :inputName="'variables_' + key"
										   :ref="'variables_' + key"
										   :input-label="variable.label + ':'"
										   :input-value="(!this.isEmpty(this.item.variables[key])) ? this.item.variables[key] : ((this.variables_list[variable.id]) ? this.variables_list[variable.id]['default'] : null)"
										   inputLabelClass="text-font-secondary mb-3"
										   inputClass="rounded-3 p-2"
										   inputWrapClass="mb-20"
										   @setValue="(value) => this.setAndValidate('variables.' + key, value)" />

							</div>
						</div>

						<InnerFieldsForm :sourceId="this.item.id"
										 sectionId="clients"
										 @addVariables="(variables) => this.updateVariablesList(variables)" />

					</div>
					<!-- /Для документов -->

				</div>

				<button
					type="button"
					class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
					@click.stop.prevent="this.addEditClient()"
					v-text="(this.item.id) ? 'Сохранить' : 'Добавить'" />

			</form>
		</div>
	</div>

	<ConfirmModal id="ConfirmGoBack"
				  ref="confirmGoBack"
				  title="Подтвердите действие"
				  text="У вас есть несохранённые изменения. Вы уверены, что хотите отменить редактирование!?"
				  fromSection="addEditClient"
				  @confirmed="this.goBack(true)"
				  btnText="Да" />

	<AddEditContractModal @contractAdded="(data) => { if (!this.isEmpty(data.contract)) this.item.contracts.push(data.contract) }" />

	<BankRequestModal ref="bankRequestModal"
					  @addEditRequest="(data) => this.addEditBankRequest(data)" />

	<ResultsModal id="addEditClient"
				  :state="this.resultsModalState.state"
				  :title="this.resultsModalState.title"
				  :message="this.resultsModalState.message"
				  :successButton="(!this.isEmpty(this.object_id) || this.$props.returnPath == '/add-object') ? 'Продолжить' : 'К списку клиентов'"
				  :successAction="() => this.goBack()" />

	<HistoryTasksModal />

	<SetTagsModal @saveSuccess="(tags_ids, source_id) => this.setRequisitionTags(tags_ids, source_id)" />

</template>

<script>
    import CommonService from "../services/CommonService";
	import ConfirmModal from "@/components/modals/ConfirmModal";
	import SelectBox from "@/components/inputs/SelectBox";
	import InputText from "@/components/inputs/InputText";
	import TextArea from "@/components/inputs/TextArea";
	import Switcher from "@/components/inputs/Switcher";
	import api from "@/api";
	import * as bootstrap from "bootstrap";
	import FormSteps from "@/components/common/FormSteps";
	import ObjectsList from "@/components/objects/ObjectsList";
	import AddEditContractModal from "@/components/modals/AddEditContractModal";
	import ResultsModal from "@/components/modals/ResultsModal";
	import RequestCard from "@/components/requisitions/RequestCard";
	import Loader from "@/components/common/Loader";
	import InputNumber from "@/components/inputs/InputNumber";
	import InnerFieldsForm from "@/components/forms/InnerFieldsForm";
	import EmptyList from "@/components/common/EmptyList";
	import DateTimePicker from "@/components/inputs/DateTimePicker";
	import BankRequestModal from "@/components/modals/BankRequestModal";
	import HistoryTasksModal from "@/components/modals/HistoryTasksModal";
	import InputSelect from "@/components/inputs/InputSelect";
	import SetTagsModal from "@/components/modals/SetTagsModal";
	import {CommonDataService} from "@/services/CommonDataService";

    export default {
        name: "addEditClient",
		props: {
			returnPath: {type: String},
			clientId: {type: [ String, Number ]},
			objectId: {type: [ String, Number ]},
			funnelId: {type: [ String, Number ]},
			restoreForm: {type: [ Boolean ]},
		},
		components: {
			SetTagsModal,
			InputSelect,
			HistoryTasksModal,
			BankRequestModal,
			DateTimePicker,
			EmptyList,
			InnerFieldsForm,
			InputNumber,
			Loader,
			RequestCard,
			//StageBar,
			ResultsModal,
			AddEditContractModal,
			ObjectsList,
			FormSteps,
			SelectBox,
			InputText,
			TextArea,
			Switcher,
			ConfirmModal
		},
        data() {
            return {
              national_phone: 0,
              national_phones: [],
                title: {
					default: "Новый клиент",
					edit: "Редактирование клиента",
				},
				form_step: 1,
				form_steps: [
					{
						id: 1,
						title: 'Общие',
						icon: null,
					}, {
						id: 2,
						title: 'Ипотека',
						icon: null,
					}, {
						id: 3,
						title: 'Кредит',
						icon: null,
					}, {
						id: 4,
						title: 'Объекты',
						icon: null,
					}, {
						id: 5,
						title: 'Заявки',
						icon: null,
					}, {
						id: 6,
						title: 'Для документов',
						icon: null,
					},
				],
				client_id: (typeof this.clientId !== "undefined" && this.clientId) ? this.clientId : null,
				object_id: (typeof this.objectId !== "undefined" && this.objectId) ? this.objectId : null,
				funnel_id: (typeof this.funnelId !== "undefined" && this.funnelId) ? this.funnelId : null,
				restore_form: (typeof this.restoreForm !== "undefined" && this.restoreForm) ? true : false,
				item: {
					name: null,
					funnel_id: null,
					phone: null,
					phones: [],
					contracts: [],
					hypothec: {
						banks: [],
						resolve: [],
					},
					credit: [],
					objects: [],
					requisitions: [],
					types: [],
					roles: [],
				},
				user_cant_edit: false,
				is_form_changed: false,
				funnels_list: [],
				//stages_list: [],
				phones_count: 0,
				phones_max: 10,
				requisitions_list: [],
				client_types: [],
				tags_list: [],
				tags_list2: [],
				types_list: [],
				client_roles: [],
				estate_types: [],
				complexes_list: [],
				objects_list: [],
				employees_list: [],
				sources_list: [],
				partners_list: [],
				bank_products: [],
				contract_types: [],
				banks_list: [],
				fields_list: [],
				variables_list: [],
				resultsModalState: {
					state: '',
					title: '',
					message: ''
				},
				requisitions: [],
				errors: [],
				is_loading_process: true,
				requisitions_proccessed: true,
            };
        },
        methods: CommonService.mergeRecursive({
          isNationalPhone(phone) {
            if (!phone.startsWith('+7')) {
              return 1;
            }
            if (String(phone).replace(/[^0-9]/g,"").length !== 11) {
              return 1;
            }
            return 0;
          },
			goBack(forced) {

				if (!forced) {
					if (this.is_form_changed && this.isEmpty(this.$props.returnPath)) {
						let confirm = CommonService.getModal('addEditClientConfirmGoBack');
						confirm.show();
					} else {
						let path = '/clients';
						if (!this.isEmpty(this.$props.returnPath))
							path = this.$props.returnPath;

						let query = [];
						if (!this.isEmpty(this.item.funnel_id))
							query.push('funnel_id=' + this.item.funnel_id);

						if (!this.isEmpty(this.object_id))
							query.push('id=' + this.object_id)

						if (!this.isEmpty(this.item.id))
							query.push('client_id=' + this.item.id);

						if (this.inArray(this.$props.returnPath, [
							'/add-object',
							'/edit-object',
							'/add-requisition',
							'/edit-requisition'
						])) {
							query.push('restore_form=true');
						}

						if (query.length > 0)
							path = path + '?' + query.join('&');

						if (!this.isEmpty(this.item.id))
							path = path + '#item_' + this.item.id.toString();

						this.$router.push(path);
					}
				} else {
					window.history.back();
				}
			},
			getClient() {
				return api.get('/client', {
					params: {
						client_id: this.client_id,
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getClient()::axios', {response: response.data});

					if (response.status == 200) {

						if (response.data.user_can_edit == false)
							this.user_cant_edit = true;

						if (response.data.success && !this.isEmpty(response.data.item))
							return response.data.item;

					}


					return null;
				}).catch(function (error) {

					CommonService.log('error', 'getClient()::axios', error);

				});
			},
			getFunnelsList(section) {
				if (typeof section !== "undefined") {
					return api.get('/common/funnels', {
						params: {
							section: section
						}
					})
					.then((response) => {

						CommonService.log('debug', 'getFunnelsList()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {
							let list = [];
							Object.entries(response.data.list).forEach(([key, option]) => {
								list.push({
									value: option.id,
									name: option.name
								});
							});

							/*if (section == 'clients')
								this.$store.commit('setFunnelsList', {
									section: 'clients',
									list: list
								});

							if (section == 'requisitions')
								this.$store.commit('setFunnelsList', {
									section: 'requisitions',
									list: list
								});*/

							return list;
						} else {
							return {};
						}

					}).catch(function (error) {

						CommonService.log('error', 'getFunnelsList()::axios', error);

					});
				}
			},
			getFieldsList(section) {
				if (typeof section !== "undefined") {
					return api.get('/fields', {
						params: {
							section: section
						}
					})
					.then((response) => {

						CommonService.log('debug', 'getFieldsList()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {
							return response.data.list;
						} else {
							return {};
						}

					}).catch(function (error) {

						CommonService.log('error', 'getFieldsList()::axios', error);

					});
				}
			},
			getVariablesList(section) {
				if (typeof section !== "undefined") {
					return api.get('/docs/variables', {
						params: {
							section: section
						}
					})
					.then((response) => {

						CommonService.log('debug', 'getVariablesList()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {
							return response.data.list;
						} else {
							return {};
						}

					}).catch(function (error) {

						CommonService.log('error', 'getVariablesList()::axios', error);

					});
				}
			},
			/*getStages(funnel_id) {
				return api.get('/common/stages-list', {
					params: {
						funnel_id: funnel_id
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getStages()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let list = [];
						if (response.data.list) {
							Object.entries(response.data.list).forEach(([key, item]) => {
								list.push({
									value: item.id,
									name: item.name
								});
							});
						}
						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getStages()::axios', error);

				});
			},*/
			async getRequisitionsList(items_id) {

				let url = '/common/requisitions';
				if (typeof items_id !== "undefined" && items_id !== null)
					url = '/requisitions';

				this.requisitions_proccessed = true;

				let _this = this;
				return api.get(url, {
					params: {
						client_id: this.item.id ?? null,
						funnel_id: this.item.funnel_id ?? null,
						filters: {
							id: items_id ?? null,
							type: this.item.type ?? null,
						}
					}
				}).then((response) => {

					CommonService.log('debug', 'getRequisitionsList()::axios', {response: response.data});

					this.requisitions_proccessed = false;

					if (response.status == 200 && response.data.success) {

						let list = [];
						if (typeof items_id !== "undefined") {
							Object.entries(response.data.list).forEach(([key, item]) => {
								list.push(item);
							});
						} else {
							Object.entries(response.data.list).forEach(([key, option]) => {

								let requisition = option.name;

								let contacts = '';
								if (option.phone && option.phone !== '')
									contacts = option.phone;

								if (option.email && contacts !== '')
									contacts = contacts + ', ' + option.email;
								else if (option.email)
									contacts = option.email;

								if (contacts !== '')
									requisition = requisition + ' (' + contacts + ')';

								list.push({
									value: option.id,
									name: requisition,
									label: option.name,
								});
							});
						}

						return list;
					}

					return {};

				}).catch(function (error) {

					CommonService.log('error', 'getRequisitionsList()::axios', error);

					this.requisitions_proccessed = false;
				});
			},
			getTypes(section) {
				if (typeof section !== "undefined") {
					return api.get('/common/types', {
						params: {
							section: section
						}
					})
						.then((response) => {

							CommonService.log('debug', 'getTypes()::axios', {response: response.data});

							if (response.status == 200 && response.data.success) {
								let list = [];
								Object.entries(response.data.list).forEach(([key, option]) => {
									list.push({
										value: option.id,
										name: option.name
									});
								});

								return list;
							} else {
								return {};
							}

						}).catch(function (error) {

							CommonService.log('error', 'getTypes()::axios', error);

						});
				}
			},
			getTags(section) {
				return api.get('/common/tags', {
					params: {
						with_managers: true
					}
				}).then((response) => {

					CommonService.log('debug', 'getTags()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = [];
						Object.entries(response.data.list).forEach(([key, option]) => {
							if (section == 'requisitions') {
								list.push({
									id: option.id,
									name: option.name
								});
							} else {
								list.push({
									value: option.id,
									name: option.name
								});
							}
						});
						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getTags()::axios', error);

				});
			},
			getRoles() {
				return api.get('/common/roles', {
					params: {
						section: 'clients'
					}
				})
					.then((response) => {

						CommonService.log('debug', 'getRoles()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {

							let list = [];
							if (response.data.list) {
								Object.entries(response.data.list).forEach(([key, role]) => {
									list.push({
										value: role.id.toString(),
										name: role.name
									});
								});
							}
							return list;
						} else {
							return {};
						}

					}).catch(function (error) {

						CommonService.log('error', 'getRoles()::axios', error);

					});
			},
			getObjects() {
				return api.get('/objects/list', {
					params: {
						objects: this.item.objects
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getObjects()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let list = [];
						let groups = (response.data.groups) ? response.data.groups : [];

						if (response.data.list) {
							Object.entries(response.data.list).forEach(([key, object]) => {

								if (object.group_id) {

									let group_id = object.group_id;
									if (groups[group_id]) {

										list.push({
											value: false,
											name: '<b>' + groups[group_id] + '</b>'
										});

										delete groups[group_id];
									}
								}

								list.push({
									value: object.id,
									name: object.name
								});
							});
						}

						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getObjects()::axios', error);

				});
			},
			getEmployees() {
				return api.get('/common/employees', {
					params: {
						add_groups: true
					}
				})
					.then((response) => {

						CommonService.log('debug', 'getEmployees()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {

							let list = [];
							let groups = (response.data.groups) ? response.data.groups : [];
							//console.log(groups);

							if (response.data.list) {
								Object.entries(response.data.list).forEach(([key, employee]) => {

									if (employee.group_id) {

										let group_id = employee.group_id;
										if (groups[group_id]) {

											list.push({
												value: false,
												name: '<b>' + groups[group_id] + '</b>'
											});

											delete groups[group_id];
										}
									}

									let department_name = (employee.department)  ? ' (' + employee.department + ')' : '';
									list.push({
										value: employee.id,
										name: (employee.group_id) ? '&nbsp;&nbsp;-&nbsp;' + employee.name : employee.name + department_name
									});
								});
							}

							return list;
						} else {
							return {};
						}

					}).catch(function (error) {

						CommonService.log('error', 'getEmployees()::axios', error);

					});
			},
			getSources() { // @todo: need to remove, implement in `CommonDataService`

				let _this = this;
				return api.get('/common/sources', {
					params: {}
				})
				.then((response) => {

					CommonService.log('debug', 'getSources()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let list = [];
						if (response.data.list) {
							Object.entries(response.data.list).forEach(([key, source]) => {
								list.push({
									value: source.id.toString(),
									name: source.name
								});
							});
						}

						CommonService.log('info', 'AddClient::getSources()', list);

						if (typeof _this.$store !== "undefined")
							_this.$store.commit('setSourcesList', list);

						return list;
					} else {
						return {};
					}
				}).catch(function (error) {

					CommonService.log('error', 'getSources()::axios', error);

				});
			},
			getList(section) {
				return api.get('/common/list', {
					params: {
						section: section
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getList()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let list = [];
						if (response.data.list) {
							Object.entries(response.data.list).forEach(([key, item]) => {
								list.push({
									value: item.id,
									name: item.name
								});
							});
						}
						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getList()::axios', error);

				});
			},
			getContractType(type_id) {

				let contract_type = false;
				if (typeof type_id !== "undefined" && !this.isEmpty(this.contract_types)) {
					this.contract_types.forEach((type) => {
						if (type.value == type_id)
							contract_type = type.name;
					});
				}

				return contract_type;
			},
			getPartners() {
				return api.get('/partners/list', {
					params: {}
				})
				.then((response) => {

					CommonService.log('debug', 'getPartners()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let list = [];
						let groups = (response.data.groups) ? response.data.groups : [];
						if (response.data.list) {
							Object.entries(response.data.list).forEach(([key, employee]) => {

								if (employee.group_id) {

									let group_id = employee.group_id;
									if (groups[group_id]) {

										list.push({
											value: false,
											name: '<b>' + groups[group_id] + '</b>'
										});

										delete groups[group_id];
									}
								}

								let positions = (employee.positions)  ? ' (' + employee.positions + ')' : '';
								list.push({
									value: employee.id,
									name: (this.isEmpty(positions)) ? '&nbsp;&nbsp;-&nbsp;' + employee.name : employee.name + positions
								});
							});
						}

						return list;
					}

					return {};
				}).catch(function (error) {

					CommonService.log('error', 'getPartners()::axios', error);

				});
			},
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			inArray(needle, haystack) {
				return CommonService.inArray(needle, haystack);
			},
			formatDate(format, datetime) {
				return CommonService.formatDateTime(datetime, 'ru', format);
			},
			sortArray(data) {
				return CommonService.sortArray(data);
			},
			showNote(message) {
				return alert(message);
			},
			async checkPhoneUnique(phone) {

				if (phone && typeof phone != "undefined")
					phone = phone.toString().replace(/[^\d]/g, "");

				let response = api.get('/clients/check-phone', {
					params: {
						phone: phone,
						client_id: this.item.id,
						funnel_id: this.item.funnel_id,
					}
				})
				.then((response) => {

					CommonService.log('debug', 'checkPhoneUnique()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						return {is_valid: Boolean(response.data.is_unique), agent_name: response.data.agent_name};
					}
				}).catch(function (error) {

					CommonService.log('error', 'checkPhoneUnique()::axios', error);

				});

				return response;
			},
			toRoute(url) {
				this.$store.commit('setFormsState', { 'addClient': this.item });
				this.$router.push(url);
			},
			icon(path, class_name, title, size) {
				return CommonService.buildIconImage(path, class_name, title, size);
			},
      addPhone() {
        const length = Object.keys(this.item.phones).length;
        this.item.phones[length] = "";
        this.phones_count++;
        this.national_phones[length] = 0;
      },
			removePhone(index, value) {

				this.item.phones.splice(index, 1);
				this.national_phones.splice(index, 1);

				if (this.phones_count > 0)
					this.phones_count--;

			},
			updateVariablesList(variables) {

				if (!this.isEmpty(variables))
					this.variables_list = variables;

				CommonService.log('debug', 'updateVariablesList()', variables);

			},
			addEditBankRequest(data) {

				if (data.section == 'request-bank' && !this.isEmpty(data.request)) {
					this.item.hypothec.banks.push(Object.values(data.request)[0]);
				} else if (data.section == 'resolve-bank' && !this.isEmpty(data.request)) {
					this.item.hypothec.resolve.push(Object.values(data.request)[0]);
				}

				CommonService.log('debug', 'addEditBankRequest()', data);

			},
			removeBankRequest(section, bank_id) {
				if (section == 'request-bank' && !this.isEmpty(this.item.hypothec.banks)) {
					const index = this.item.hypothec.banks.findIndex(item => item.id == bank_id);
					if (index !== -1) {
						this.item.hypothec.banks.splice(index, 1);
					}
				} else if (section == 'resolve-bank' && !this.isEmpty(this.item.hypothec.resolve)) {
					const index = this.item.hypothec.resolve.findIndex(item => item.id == bank_id);
					if (index !== -1) {
						this.item.hypothec.resolve.splice(index, 1);
					}
				}

				CommonService.log('debug', 'removeBankRequest()', {section, bank_id});

			},
			showBankRequestModal(section, request) {

				CommonService.log('debug', 'showBankRequestModal()', {section, request});

				this.$refs.bankRequestModal.section = section;
				this.$refs.bankRequestModal.request = request;

				let modal = CommonService.getModal('bankRequestModal');
				if (modal)
					modal.show();

			},
			setRequisitionTags: function (tags, source_id) {
				if (typeof tags !== "undefined") {

					let _this = this;
					[].forEach.call(_this.requisitions, function (item, key) {
						if (parseInt(item.id) == parseInt(source_id) && parseInt(source_id) !== 0) {
							_this.requisitions[key].tags = tags;
						}
					});
				}

				CommonService.log('debug', 'setRequisitionTags', {tags: tags, source_id: source_id});

			},
			setAndValidate(name, value) {

				let errors = [];
				let parent = null;
				let child = null;
				let parts = name.split('.', 2);
				if (parts.length == 2) {
					parent = parts[0];
					child = parts[1];
				}

				// Проверки перед установкой значения
				if (name) {

					/*if (name == 'is_hot') {
						if (value == this.item['is_hot'])
							value = 0;
						else
							value = this.item['is_hot'];

					}

					if (name == 'priority') {
						if (value == this.item['priority'])
							value = 0;
						else
							value = this.item['is_hot'];

					}*/

					if (typeof value == 'string' && this.inArray(name, [
							'budget',
							'hypothec.object_price',
							'hypothec.first_pay_price',
							'hypothec.co_borrower_income',
							'hypothec.borrower_income',
							'hypothec.borrower_credit_load',
							'credit.sum',
							'credit.load',
							'credit.income'
						])) {
						value = parseInt(value.replace(/[^0-9.-]+/g, ""));
					}

					if (name == 'hypothec.is_married' && !this.item['hypothec']['is_married'])
						this.item['hypothec']['is_married_contract'] = 0;

				}

				// Валидация значения


				const field = Array.isArray(this.$refs[name]) ? this.$refs[name][0] : this.$refs[name]

				if (field && typeof field !== "undefined") {

					/*if ('invalid' in this.$refs[name])
						this.$refs[name].invalid = false;*/

					if (field.inputLabel) {
						let label = field.inputLabel.replace(/:+$/, '');

						let is_skip = false;
						if (name == 'funnel_id' && value == 0)
							is_skip = true;

						if (field.required && !is_skip) {
							
							if (value) {
								if (value.toString() == '0' || value.toString() == '-1')
									errors.push('Поле `' + label + '` обязательно к заполнению!');
								else if (this.isEmpty(value))
									errors.push('Поле `' + label + '` обязательно к заполнению!');
							} else {
								errors.push('Поле `' + label + '` обязательно к заполнению!');
							}
						}

						if (name == 'phone' && !this.isEmpty(value) && value.length < 12) {
              if (!(this.national_phone && /^\+[\d ()-]+$/.test(value))) {
                errors.push('Поле `' + label + '` заполнено не верно. Формат для российских номеров: +7 (XXX) XXX-XX-XX, для зарубежных: +XXXXXXXXXXX.');
              } else {
                errors = [];
              }
						} else if (name == 'phone' && this.isEmpty(this.item.id)) {
							let _this = this;
							let input_name = name;
							let input_label = label;
							this.checkPhoneUnique(value).then((data) => {

								if (data && !data.is_valid) {

									let error = '';
									if (!_this.isEmpty(data.agent_name))
										error = new Array('Клиент с таким номером телефона уже добавлен в систему и закреплен за агентом ' + data.agent_name + '.');
									else
										error = new Array('Клиент с таким номером телефона уже добавлен в систему.');

									_this.errors[input_name] = {
										name: input_name,
										label: input_label,
										errors: error
									};

									_this.$refs[input_name].valid = false;
									_this.$refs[input_name].invalid = error.join('<br/>');
								}
							});
						}

						if (name == 'email' && !this.isEmpty(value) && !CommonService.validateEmail(value))
							errors.push('Поле `' + label + '` заполнено не верно.');

						if (!this.isEmpty(errors)) {
							this.errors[name] = {
								name: name,
								label: label,
								errors: errors
							};

							field.valid = false;
							field.invalid = errors.join('<br/>');
						} else {
							this.errors[name] = null;
							field.valid = true;
							field.invalid = false;
						}
					}
				}

				// Установка значения
				if (parent && child) {
					if (!this.isEmpty(this.item[parent])) {
						if (typeof (this.item[parent][child]) !== "undefined") {
							this.item[parent][child] = value;
						} else {
							this.item[parent] = {
								...{
									[child]: value
								},
								...this.item[parent]
							};
						}
					} else {
						this.item[parent] = {
							[child]: value
						};
					}
				} else if (parent == null && name) {
					this.item[name] = value;
				} else {
					if (parent && child)
						this.item[parent][child] = null;
					else
						this.item[name] = null;
				}

				this.is_form_changed = true;

				CommonService.log('debug', 'setAndValidate()', { name: name, value: value, is_form_changed: this.is_form_changed, errors: errors });


				if (!this.isEmpty(errors))
					return false;

				return true;
			},
			validateAll(fields) {

				this.errors = [];
				let is_valid = false;
				let collection = CommonService.proxyToObject(fields);
				let invalid_input = null;
				let blocks = [];

				for (const [name, value] of Object.entries(collection)) {

					let input_is_valid = true;
					if (typeof name !== "undefined") {
						if (name == 'fields' && !this.isEmpty(this.fields_list)) {

							let entries = [];
							if (!this.isEmpty(value))
								entries = Object.entries(value);

							for (const [field_name, field_value] of entries) {
								if (!this.setAndValidate('fields.' + field_name, field_value, true)) {
									input_is_valid = false;
								}
							}
						} else if (this.inArray(name, blocks)) {
							blocks.forEach((block_name) => {
								if (name == block_name) {

									let entries = [];
									if (!this.isEmpty(value))
										entries = Object.entries(value);

									for (const [field_name, field_value] of entries) {
										if (!this.setAndValidate(block_name + '.' + field_name, field_value, true)) {
											input_is_valid = false;
										}
									}
								}
							});
						} else {
							if (!this.setAndValidate(name, value, true)) {
								input_is_valid = false;
							}
						}
					}

					CommonService.log('log', 'Validate: ' + name + ', is valid? - ' + input_is_valid.toString());

				}

				if (typeof Object.values(this.errors) == "object") {
					this.errors = CommonService.removeEmpty(Object.values(this.errors));
					//this.errors = CommonService.resetArray(Object.values(this.errors));
					this.errors = Object.values(this.errors);
					if (typeof (this.errors[0]) !== "undefined") {
						if (!this.isEmpty(this.errors[0].name)) {
							let invalid_input = this.errors[0].name;
							if (invalid_input && typeof invalid_input !== "undefined") {
								let input = Array.isArray(this.$refs[invalid_input]) ? this.$refs[invalid_input][0].$el : this.$refs[invalid_input].$el;
								const inputes = Object.values(this.errors).map(error => (Array.isArray(this.$refs[error.name]) ? this.$refs[error.name][0] : this.$refs[error.name]).$el)
								if (input) {
									this.$nextTick(() => CommonService.scrollIntoMaxTopView(inputes, 70));
								}
							}
						}
					}
				}

				is_valid = this.isEmpty(this.errors);

				CommonService.log('debug', 'validateAll()', {fields: collection, is_valid: is_valid, errors: this.errors});

				return is_valid;
			},
			addEditClient: function () {

				CommonService.log('debug', 'addEditClient()', this.item);

				if (!this.isEmpty(this.item.phones))
					this.item.phones = Object.values(this.item.phones).filter(phone => phone !== null);

				if (!this.isEmpty(this.object_id))
					this.item.objects = [this.object_id];

				this.item.is_hot = Boolean(this.item.is_hot);
				this.item.priority = Number(this.item.priority);

				if (this.validateAll(this.item)) {

					this.is_form_changed = false;
					api.post('/client/add', this.item).then((response) => {

						if (process.env.NODE_ENV == "development")
							console.debug('addEditClient()::axios', {
								response: response.data
							});

						if (response.status == 200 && response.data.success) {

							this.$store.commit('resetFormsState', 'addClient');
							this.resultsModalState.state = 'success';

							if (this.item.id)
								this.resultsModalState.title = 'Клиент был успешно сохранён!';
							else
								this.resultsModalState.title = 'Новый клиент был успешно добавлен!';

							if (!this.isEmpty(response.data.client_id))
								this.item.id = response.data.client_id;

						} else {
							this.resultsModalState.state = 'error';

							if (this.item.id)
								this.resultsModalState.title = 'Ошибка сохранения клиента.';
							else
								this.resultsModalState.title = 'Ошибка добавления нового клиента.';

							if (!this.isEmpty(response.data.errors))
								this.resultsModalState.message = response.data.errors.join('<br/>');

						}

					}).catch(function (error) {

						CommonService.log('error', 'addEditClient()::axios', error);

					});

					this.resultsModalState.state = '';
					this.resultsModalState.title = '';
					this.resultsModalState.message = '';
				}
			},
			getCustomFieldValue(key) {

				if (!this.isEmpty(key) && !this.isEmpty(this.item.fields))
					return this.item.fields[key];

				return null;
			},
        }, CommonDataService.methods),
        mounted() {

			if (this.client_id !== null) {
				CommonService.setPageTitle(this.title['edit']);

				if (location.href.indexOf("#requisitions") != -1) {
					this.form_step = 5; // Заявки
				}

				if (this.restore_form) {

					let stored = this.$store.getters.formState['addClient'];
					CommonService.log('debug', 'getFormState', stored);

					if (typeof stored !== "undefined")
						this.item = stored;
          if (this.item.phone) {
            this.national_phone = this.isNationalPhone(this.item.phone);
          }
				} else {
					this.$store.commit('resetFormsState', 'addClient');
					this.getClient().then(data => {

						this.item = {...this.item, ...data};

            if (this.item.phone) {
              this.national_phone = this.isNationalPhone(this.item.phone);
            }
						if (!this.isEmpty(this.item.phones)) {
              this.item.phones = this.item.phones.filter(phone => phone !== this.item.phone);
              this.phones_count = Object.entries(this.item.phones).length;
              for (const index in this.item.phones) {
                this.national_phones[index] = this.isNationalPhone(this.item.phones[index])
              }
            }

						if (this.isEmpty(this.item.funnel_id)) {
							this.item.funnel_id = 0;
						}

						//this.getStages(this.item.funnel_id).then(list => this.stages_list = list);

						// Дополнительные поля
						if (!this.isEmpty(this.item.fields)) {
							this.fields_list = this.item.fields;
							this.item.fields = {};
							Object.entries(this.fields_list).forEach(([key, option]) => {
								this.item.fields[key.toString()] = option.value;
							});
						}

						// Переменные для документов
						if (!this.isEmpty(this.item.variables)) {
							this.variables_list = this.item.variables;
							this.item.variables = {};
							Object.entries(this.variables_list).forEach(([key, option]) => {
								this.item.variables[key.toString()] = option.value;
							});
						}

						// Заявки клиента
						if (!this.isEmpty(this.item.requisitions)) {

							this.getSources().then(data => {
								this.sources_list = data
							});

							this.getPartners().then(data => {
								this.partners_list = data
							});

							this.getTypes('requisitions').then(data => {
								this.types_list = data
							});

							this.getTypes('contract_types').then(data => {
								this.contract_types = data
							});

							this.getFunnelsList('clients').then(data => {
								this.funnels_list = data
							});

							if (!this.isEmpty(this.item.requisitions)) {
								this.getRequisitionsList(this.item.requisitions).then(data => {
									this.requisitions = data
								});
							} else {
								this.requisitions = null;
							}
						}

						// Данные формы по ипотеке
						if (!this.isEmpty(this.item.hypothec)) {
							if (
								!this.isEmpty(this.item.hypothec.co_borrower_relationship) ||
								!this.isEmpty(this.item.hypothec.co_borrower_name) ||
								!this.isEmpty(this.item.hypothec.co_borrower_age) ||
								!this.isEmpty(this.item.hypothec.co_borrower_contacts)
							) {
								this.item.hypothec.is_co_borrower = 1;
							}

							if (
								!this.isEmpty(this.item.hypothec.co_borrower_income) ||
								!this.isEmpty(this.item.hypothec.borrower_income) ||
								!this.isEmpty(this.item.hypothec.borrower_credit_load)
							) {
								this.item.hypothec.is_income_taken = 1;
							}

							if (!this.isEmpty(this.item.hypothec.banks)) {
								let banks = [];
								Object.entries(this.item.hypothec.banks).forEach((item) => {
									item[1].id = parseInt(item[0]);
									banks.push(Object.values(item)[1]);
								});
								this.item.hypothec.banks = banks;
							} else {
								this.item.hypothec.banks = [];
							}

							if (!this.isEmpty(this.item.hypothec.resolve)) {
								let banks = [];
								Object.entries(this.item.hypothec.resolve).forEach((item) => {
									item[1].id = parseInt(item[0]);
									banks.push(Object.values(item)[1]);
								});
								this.item.hypothec.resolve = banks;
							} else {
								this.item.hypothec.resolve = [];
							}
						}

						this.is_loading_process = false;
					});
				}
			} else {

				CommonService.setPageTitle(this.title['default']);

				if (this.restore_form) {

					let stored = this.$store.getters.formState['addClient'];
					CommonService.log('debug', 'getFormState', stored);

					if (typeof stored !== "undefined")
						this.item = stored;

				} else {
					this.$store.commit('resetFormsState', 'addClient');
				}

				if (this.funnel_id) {
					this.item.funnel_id = this.funnel_id;
				} else {
					this.item.funnel_id = 0;
				}

				if (this.funnel_id == 0)
					this.item.stage_id = 1;

				//this.getStages(this.item.funnel_id).then(list => this.stages_list = list);

				this.item.employee_id = this.$store.getters.userInfo.id;

				this.getFieldsList('clients').then(data => {
					this.fields_list = data;
					if (!this.isEmpty(this.fields_list)) {
						this.item.fields = {};
						Object.entries(this.fields_list).forEach(([key, option]) => {
							if (!this.isEmpty(key))
								this.item.fields[key.toString()] = option.value;
						});
					}
				});
				this.getVariablesList('clients').then(data => {
					this.variables_list = data;
					if (!this.isEmpty(this.variables_list)) {
						this.item.variables = {};
						Object.entries(this.variables_list).forEach(([key, option]) => {
							if (!this.isEmpty(key))
								this.item.variables[key.toString()] = option.value;
						});
					}
				});

				this.is_loading_process = false;
			}

			this.getFunnelsList('clients').then(data => { this.funnels_list = data });

			this.getRequisitionsList().then(data => {
				this.requisitions_list = [...this.requisitions_list, ...data];
			});

			this.getTypes('clients').then(data => { this.client_types = this.sortArray(data) });
			this.getTags().then(data => { this.tags_list = this.sortArray(data) });
			this.getTags('requisitions').then(data => { this.tags_list2 = this.sortArray(data) });
			this.getRoles().then(data => { this.client_roles = this.sortArray(data) });
			this.getTypes('estate_types').then(data => { this.estate_types = this.sortArray(data) });
			this.getObjects().then(data => { this.objects_list = data });
			this.getEmployees().then(data => { this.employees_list = data });
			this.getSources().then(data => { this.sources_list = data });
			this.getPartners().then(data => { this.partners_list = data });
			this.getList('complexes').then(data => { this.complexes_list = this.sortArray(data) });
			this.getList('banks_list').then(data => { this.banks_list = this.sortArray(data) });
			this.getTypes('bank_products').then(data => { this.bank_products = this.sortArray(data) });
			this.getTypes('contract_types').then(data => { this.contract_types = data });
        },
		watch: {
			variables_list(variables, oldVariables) {

				/*if (typeof variables == "object") {
					if (!this.isEmpty(variables)) {
						variables.forEach((variable) => {
							if (this.isEmpty(variable.value) && this.isEmpty(variable.default)) {
								variable.value = variable.default;
							}
						});

						this.variables_list = variables;
					}
				}

				CommonService.log('debug', 'watch::variables_list', this.variables_list);*/

				this.$emit('setVariables', this.variables_list);
			},
			client_id: function() {

				this.client_id = this.clientId ?? null;

				CommonService.log('debug', 'watch::client_id', this.client_id);

				return this.client_id;
			},
			search_query(value, oldValue) {
				if ((value !== oldValue && (value.length >= 3 || oldValue.length >= 3)) || value.length == 0) {
					this.getRequisitionsList().then(data => {
						this.requisitions_list = data;
					});
				}
			},
			requisitions_offset(value, oldValue) {
				if (value !== oldValue && value != 0) {
					this.getRequisitionsList().then(data => {
						this.requisitions_list = [...this.requisitions_list, ...data];
					});
				}
			},
		},
    };
</script>

<style lang="scss">
  .fix-width {
    width: 100px;
    text-align: right;
  }
	.add-client {
		.step-list {
			@media(max-width: 480px) {
				flex-direction: column;
				align-items: flex-start !important;

				li:not(:last-child) {
					margin-bottom: 20px;
				}
			}
		}
	}
</style>
